import React from "react";
import { Router } from "@reach/router";

// import SystemAddPage from "../components/PageComponents/SystemAddPage";
// import SystemUpdatePage from "../components/PageComponents/SystemUpdatePage";

import SystemListingPage from "../components/PageComponents/SystemListingPage";
import SystemAddPage from "../components/PageComponents/SystemAddPage";
import SystemUpdatePage from "../components/PageComponents/SystemUpdatePage";

const systems = (props) => (
  <Router>
    <SystemListingPage {...props} path="/systems" />
    <SystemAddPage {...props} path="systems/add-systems" />
    <SystemUpdatePage {...props} path="systems/:systemId" />
  </Router>
);

export default systems;
